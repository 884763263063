


.database-image-preview {
    padding-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    img {
        width: 100%;
        border: 3px solid #eee;
        border-radius: 5px;
        cursor: pointer;
    }
}

.image-capture {
    .react-html5-camera-photo  {
        text-align: left !important;
        position: relative;
        display: inline-block;
        margin-bottom: 20px;

        img {
            width: 100%;
        }
    }

    .photo-previews {
        display: grid;
        max-width: 600px;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 30px;
        img {
            width: 100%;
            border: 3px solid $clr-4;
            border-radius: 5px;
            cursor: pointer;
        }

        > div {
            position: relative;
            button{
                position: absolute;
                bottom: 5px;
                left: 5px;
                background-color: rgba($clr-4, .7);
                padding: 3px 5px;
                height: 20px;
                line-height: 10px;
            }
        }

    }

    video {
        width: 100% !important;
    }

   

    #outer-circle {
        position: absolute;
        left: 50%;
        bottom: 45px;
        transform: translateX(-50%);
        width: 80px;
        height: 80px;
        background-color: rgba($clr-4, .3);
        z-index: 9;
        border-radius: 50%;
        cursor: pointer;

        @include tablet {
            width: 60px;
            height: 60px;
            bottom: 35px;
        }

        #inner-circle {
            position: absolute;
            left: 50%;
            bottom: 50%;
            transform: translate(-50%, 50%);
            width: 60px;
            height: 60px;
            background-color: $clr-4;
            z-index: 9;
            border-radius: 50%;

            @include tablet {
                width: 40px;
                height: 40px;
            }
        }

    }

    .client-details {
        padding-bottom: 40px;
        button {
            margin-top: 20px;
        }
    }

}


.photo-capture, .client-database, .see-photo {

    .ant-modal-body {
        padding: 0 !important;
    }

    .ant-modal {
        max-width: 600px !important;
        width: 100% !important;
        img {
            width: 100%;
        }
    }
}

.see-client-photos {
    .preview-images {
        display: grid;
        max-width: 600px;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 30px;
        margin-top: 10px;
        img {
            width: 100%;
            cursor: pointer;
        }
    }
}