.alert-error {
   text-align: center;
   padding: 30px 0;
   h4 {
      font-family: $primary-font;
      font-size: 30px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
      margin-bottom: -2px;
      color: $clr-6;
   }

   h2 {
      font-family: $primary-font;
      font-size: 25px;
      font-weight: 300;
   }
}