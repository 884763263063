.checkout-page-root {
   .checkout-page {
      > .loading {
          img {
              filter: invert(1) !important;
          }
      }

      .ant-picker-panel {
         padding-bottom: 20px;
      }
  }

  .frontend-page-title {
     position: static;
  }

  .react-tel-input {
      .flag-dropdown, 
      .form-control {
         border: 1px solid #999;
      }
  }
}





.front-end-checkout {

   @include mobile {
      padding: 5px;
   }

    .userInfoForm {

      margin-bottom: 15px;
      background-color: #fff;
      padding: 30px 20px;
      margin-top: 5px;

       .form-grid {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-gap: 15px;
         margin-bottom: 20px;
              
        @include mobile {
            grid-template-columns: 1fr;
         }
       }


        input {
            font-size: 15px;
            font-family: $primary-font;
        }

        .number-group {
           z-index: 5;
         .react-tel-input {

            min-height: 50px;

            .flag-dropdown {
               width: 65px;

               .selected-flag {
                  width: 65px;
                  padding-left: 15px;
               }
            }

            .country-list .search {
               display: grid;
               grid-template-columns: 1fr 7fr;
               padding-right: 12px;
               align-items: center;
            }

            .form-control {
               width: 100%;
               min-height: 50px;
               padding-left: 72px;
            }
         }

        
        }
    }


    .userInfoForm {
   
     
        .select-input {
           grid-column: 1 / span 2;
     
           @include mobile {
              grid-column: 1 / span 1;
           }
     
           .ant-select-selector, input {
              height: 45px;
              border-radius: 5px;
              
           }
           .ant-select-selection-placeholder,
           .ant-select-selection-item {
              line-height: 45px;
           }
        }
     
        input {
           padding: 12px;
           border-radius: 5px;
           font-family: $primary-font;
           
        }
     
        .required-field {
           border: 1px solid $clr-6;
           background-color: lighten($clr-6, 50%);
        }
     
     }
     
}

.use-this-date {
   margin-bottom: 0;
   padding: 20px 0px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: fixed;
   z-index: 10;
   width: 100%;
   left: 0;

   font-family: $primary-font;
   font-size: 30px;
   box-shadow: 0 5px 15px rgba(0,0,0,.2);

   -webkit-backdrop-filter: blur(10px);
   backdrop-filter: blur(10px);
   background-color: rgba(255, 255, 255, 0.5); 

   @include mobile {
      bottom: 0 !important;
      padding: 15px 0px;
      font-size: 24px;
      height: 75px;
   }

   button {
      background-color: #1D1D1D;
    
      color: $clr-4;
      font-size: 18px;
      text-transform: capitalize;
      font-family: $primary-font;
      height: 48px;
      margin-left: 15px;
      border-radius: 50px;
      transition: .2s;
      text-align: center;
      width: 150px;
      border:1px solid #7c7c7c;
      
      &:hover, &:focus {
         background-color: $clr-2 !important;
         border-color: $clr-4 !important;
         color: #101010 !important;
         span {
            color: $clr-4 !important;

         }
      }

      &[disabled] {
         color: #e4dede;
         background: #5b5b5b;
         border-color: #d9d9d9;
      }

   }

}

.checkout-drawer .ant-drawer-close {
   padding: 6px !important;
}


/*
   -----------------------
   Checkout Page Calendar
   -----------------------
*/ 

.checkout-calendar {
   padding: 10px;

   thead {
      height: 60px;
      th {
         font-size: 18px;
      }
   }

   .ant-picker-calendar-mode-switch {
      display: none;
   }

   .ant-picker-cell-inner {
      background-color: transparent !important;
   }

   .ant-select-selector {
      height: 32px !important;
      min-width: 120px;
      border: 1px solid $clr-1 !important;

      .ant-select-selection-item {
         line-height: 30px !important;
      }
   }

   td {
      padding: 0;
      .available, .day-off, .previous-date {
         display: block;
         padding: 10px 5px;
      }

      .available {
         background-color: #0F9D58;
         color: #fff;
         &:hover {
            background-color: #0F9D58;
         }
      }

      .day-off {
         background-color: #ea4747;
         color: #fff;
      }
   }

   td {
      border: 2px solid #fff;
      box-sizing: border-box;

      .previous-date {
         background-color: #eee;
      }

      &::before {
         display: none;
      }
   }

   td::before {
      display: none;
   }

   td.ant-picker-cell-selected {
      .available {
         color: #fff;
         background-color: #101010 !important;
      }
   }

}