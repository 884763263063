.ant-input::placeholder, .ant-select-selection-placeholder {
    opacity: 1;
    color: #333333;
}


.ant-picker-input{
    cursor: pointer;
    input::placeholder,
    .ant-picker-suffix  {
        opacity: 1;
        color: #333333;
    }
}

.ant-select-selector {
    border-color: #999 !important;
    .ant-select-selection-item{
        opacity: 1;
        color: #333333;
    }

    + .ant-select-arrow {
        opacity: 1;
        color: #333333;
    }
}

.ant-input,
.rmdp-input,
.ant-checkbox-inner {
    border-color: #999;

    &::placeholder  {
        opacity: 1;
        color: #333333;
    }
}

