.checkout-drawer {
   overflow: hidden;
   .ant-btn-primary[disabled] {
      color: #fff;
      background: #7d7d7d;
   }

   .ant-drawer-content {
      background-color: rgb(243 234 223);
   }
}

.vip-price {
   span:first-child {
       color: red;
       text-decoration: line-through;
   }
   
   span:last-child {
       background-color: rgb(224,246,224);
       color: green;
       border: 1px solid rgb(125,213,125);
       padding: 5px 10px;
       display: inline-block;
       margin-left: 10px;
       font-weight: 500;
   }
}

.checkout-container {

   margin-bottom: 30px;

   table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;

      tr {

         td {
            padding: 7px 10px;
            font-family: $primary-font;
            font-size: 17px;
           

            &:first-child {
               width: 90px;
               padding: 9px 0 9px 15px;
               font-weight: 500;
            }

            @include mobile {
               padding: 5px 5px;
            }

         }

         &:nth-child(odd) {
            background-color: lighten($clr-2, 35%);
         }
         &:nth-child(even) {
            background-color: lighten($clr-2, 40%);
         }
         
      }
   }
}




.text-opt-in {
   font-family: $primary-font;
   display: none;
   .ant-checkbox-wrapper {
      span {
         font-size: 18px;
         @include mobile {
            font-size: 16px;
         }
      }
   }

   .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      &::after {
         left: 30% !important;
      }
   }
   .opt-in-details {
      font-size: 14px;
      margin-top: 5px;
      line-height: 18px;
      max-width: 360px;
      padding-left: 40px;
   }
}

.submit-btn {
   width: 100%;
   background-color: $clr-2;
   color: $clr-4;
   font-family: $primary-font;
   font-size: 18px;
   height: 50px;
   border: 0;
   margin-bottom: 25px;
   margin-top: 10px;
   border-radius: 5px;

   &:hover, &:focus {
      outline: 0;
      background-color: $clr-2;
      border: 0;
      box-shadow: none;
      opacity: .8;
   }

   @include tablet {
      position: fixed !important;
      z-index: 1 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: 100% !important;
      margin: 0 !important;
      border-radius: 0 !important;
      height: 80px !important;
      font-size: 26px;
   }

}

.ant-modal-confirm-btns {
   button:last-child {
      display: none;
   }
}

.thank-you-msg {
   margin-top: 22px;
   display: block;
}

.ant-modal-confirm-content {
   margin-left: 0 !important;
}

.ant-modal-body {
   padding: 38px 24px 31px !important;
}

.ant-modal-confirm-body > .anticon {
   font-size: 50px !important;
   float: unset !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
   font-size: 22px !important;
   margin-top: 21px !important;
}

