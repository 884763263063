.reviews-page {
   .loading {
      img {
          filter: invert(1) !important;
      }
   }
}

.yelp-review {
   background-color: $clr-4;
   padding: 45px 20px;
   margin: 50px auto 0 auto;
   border-radius: 8px 8px 0 0;
   max-width: 750px;

   @include tablet {
      margin: 50px 40px 0 40px;
   }

   @include mobile {
      margin: 0 auto 0 auto;
      border-radius: 0;
   }

   .yel-stars{
      position: relative;
      height: 30px;
      > div {
         position: absolute;
         top: 0;
         left: 0;

         svg {
            margin: 2px 3px;
            font-size: 20px;
            display: inline-block;
         }

      }

      .inactive {
         svg {
            color: #E7E7E7;
         }
      }
      .active {
         svg {
            color: #F9C23D;
         }
      }
   }


   &__header {
      text-align: center;

      .star_count {
         font-size: 50px;
         margin: 0;
         padding: 0;
         font-weight: 700;
         font-family: $primary-font;
      }

      .yel-stars {
         display: inline-block;
         width: 146px;
      }

      .based_on {
         font-family: $primary-font;
         font-size: 18px;
         margin: 0;
         padding: 0;
         margin-top: -5px;
      }

   }



   &__content {

      max-width: 600px;
      margin: 0 auto;
      padding-top: 10px;
      border-top: 2px solid #E7E7E7;
      margin-top: 30px;

      .yelp-reviewer {

         position: relative;
         margin: 30px 0;

         .yelp-reviewer__head {
            display: flex;
            align-items: flex-start;
            position: relative;

            img {
               width: 80px;
               height: 80px;
               object-fit: cover;
               border-radius: 50%;
               border:1px solid #E7E7E7;

               @include mobile {
                  width: 60px;
                  height: 60px;
               }

            }

            .yelp-logo {
               position: absolute;
               left: -5px;
               bottom: 0px;
               width: 30px;
               height: 30px;
               border: 0;
            }

            .reviewer-name {
               font-family: $primary-font;
               font-size: 24px;
               color: #101010;
               font-weight: 500;

               @include mobile {
                  font-size: 20px;
               }

               &:hover {
                  text-decoration: underline;
                  color: $clr-2;
               }
            }

            

            .yelp-reviewer-content > div {
               display: flex;
               flex-direction: row;
               position: relative;
            }

            .reviewer-no {
               font-family: $primary-font;
               font-weight: 600;
            }

            .reviewer-rating > div {
               width: 118px;

               @include mobile {
                  width: 110px;
               }

               svg {
                  font-size: 16px !important;
                  margin: 0 2px;
                  @include mobile {
                     font-size: 14px !important;
                  }
   
               }
            }

         }  

         .yelp-reviewer-content {
            width: 220px;
            padding-left: 15px;
         }

         .review-date {
            position: absolute;
            right: 0;
            top: 10px;
            font-style: italic;
            font-family: $primary-font;
            font-size: 12px;

            @include mobile {
               top: 100%;
               transform: translateY(-50%);
               background: $clr-4;
               border: 1px solid#E7E7E7;
               padding: 3px 15px;
               border-radius: 40px;


            }
         }

         .review-text {

            p {
               font-family: $primary-font;
               padding-bottom: 25px;
               border-bottom: 1px solid #E7E7E7;
               text-transform: capitalize;
               padding-left: 100px;


               @include mobile {
                  padding-left: 0px;
                  padding-top: 20px;
               }

               a {
                  color: $clr-2;
                  margin-top: 5px;
                  display: block;
                  text-decoration: underline;
                  &:hover {
                     color: $clr-3;
                  }
               }
               
            }
         }
         
         

      }
   }

   &__footer {

      margin-bottom: 40px;
      text-align: center;

      @include mobile {
         margin-bottom: 10px;
      }

      a {
         border: 1px solid $clr-2;
         display: inline-block;
         padding: 8px 25px;
         font-family: $primary-font;
         color: $clr-2;
         font-size: 18px;

         @include mobile {
            padding: 8px 15px;
            font-size: 16px;
         }

         &:hover {
            opacity: .9;
         }

         &:first-child {
            margin-right: 15px;
            background: $clr-2;
            color: $clr-4;
         }
      }
   }

}