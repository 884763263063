.admin-service-container {   

   .service-showcase {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
      grid-gap: 2px;

      @include tablet {
         grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      }

      @include mobile {
         grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      .group-btns {
         display: flex;
         column-gap: 10px;
      }

      .service-item {
         display: flex;
         background-color: $clr-4;
         padding: 10px;
         align-items: center;

         @include tablet {
            flex-direction: column;
            padding: 20px;
            text-align: center;
         }

         &-image {
            width: 120px;
            height: 130px;
            @include tablet {
               margin-bottom: 15px;
               width: 150px;
               height: 165px;
            }
            img {
               width: 120px;
               height: 130px;
               object-fit: cover;
               @include tablet {
                  width: 150px;
                  height: 165px;
               }
            }
         }

         &-content {
            padding-left: 10px;
            width: 100%;
            @include tablet {
               padding-left: 0px;
            }
            strong {
               padding-right: 6px;
            }

            button {
               margin-top: 4px;
               width: 100%;
            }
         }
      }
   }

   .add-new-service {
      margin-top: 40px;
   }
}

.admin-service-drawer {
   .ant-form-item {
      margin-bottom: 20px;

      .ant-tooltip {
         display: none;
      }
   }
}