.barber-specialties-input {
   display: grid;
   grid-template-columns: 1fr 4fr 1fr;
   grid-row-gap: 5px;
   margin-bottom: 5px;
}


.admin-barber-container {
   .barber-item-holder {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
      grid-gap: 3px;
      margin-bottom: 40px;
   }

   .admin-barber-item {
      background-color: $clr-4;
      padding: 15px 20px;

      .stylist-image {
         margin-bottom: 25px;
         img {
            height: 250px;
            object-fit: contain;
            width: 100%;
            @include mobile {
               width: 200px;
               height: 200px;
            }
         }
      }

      .text-note {
         padding: 10px 0;
      }

      .text-btns {
         padding-top: 10px;
         button:first-child {
            margin-right: 10px;
         }
      }

   }
}

.admin-barbers-drawer {
   .ant-checkbox-group {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 15px;
   }
}