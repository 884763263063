%custom-fuild{
    max-width: 1340px;
}

%transition{
    transition: all linear .2s;
}

%family-1 {
    font-family: $primary-font;
    font-size: 20px;
    font-weight: 400;
}


%service-active {
    background-color: $clr-5;
    color: $clr-4;

    .service-item--icon {
    background-image: radial-gradient($clr-4, $clr-2);
    color: $clr-3;
    }
}