.admin-individual-dayoff-drawer {
   .rmdp-day-picker  {
      div, span {
         font-size: 14px !important;
      }
   }

   .rmdp-header-values span{
      font-size: 18px !important;
   }

   .rmdp-container  {
      width: 100%;
      input {
         padding: 20px 20px;
         width: 100%;
         border-radius: 0;

        
      }

      .rmdp-day-picker > div {
         min-width: 270px;
      }

    
   }
}

.admin-individual-dayoff {


   
   .filter-barber {
      min-width: 200px;
      margin-bottom: 25px;
   }

   .admin-dayoff-container {
      display: grid;
      grid-template-columns: 1fr;
      font-family: $primary-font;
      grid-gap: 4px;

      .admin-dayoff-item {
         background-color: $clr-4;
         padding: 15px 25px;

         .date-title {
            color: $clr-2;
            display: flex;
            align-items: center;
            justify-content: space-between;
         }

         .admin-dayoff-times {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
            grid-gap: 3px;
            user-select: none;
            span {
               background-color: rgba($clr-2, .2);
               border: 1px solid $clr-2;
               display: inline-block;
               text-align: center;
               padding: 4px 0;
               cursor: pointer;
               position: relative;

               &:hover {
                  &::after {
                     opacity: 1;
                  }
               }

               &::after {
                  content: 'Delete';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  text-align: center;
                  height: 100%;
                  background-color: lighten($clr-6, 10%);
                  color: $clr-4;
                  line-height: 30px;
                  opacity: 0;
               }

            }
         }

      }

   }


   .dayoff-footer {
      margin-top: 40px;
   }
}


.admin-individual-dayoff-drawer {

   .label {
      font-size: 18px;
      margin-bottom: 15px;
      display: inline-block;      
   }

   .mark-all-timeslot {
      margin-right: 10px;
      cursor: pointer;
      user-select: none;
   }

   .ant-checkbox-group {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 8px;
      font-family: $primary-font;
      user-select: none;

      @include mobile {
         grid-template-columns: 1fr 1fr;
      }
   }

   .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
   }
}