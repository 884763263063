
@mixin shape($width, $height, $radius, $lineheight) {
    width: $width;
    height: $height;
    border-radius: $radius;
    line-height: $lineheight;
}


// responsive breakpoints
@mixin small_mobile {
    @media (max-width: $small_mobile){
        @content;
    }
}


@mixin mobile {
    @media (max-width: $mobile){
        @content;
    }
}

@mixin tablet {
    @media  (max-width: $tablet){
        @content;
    }
}


@mixin large_tablet {
    @media (max-width: $large_tablet){
        @content;
    }
}

@mixin laptop {
    @media (max-width: $laptop){
        @content;
    }
}

@mixin small_desktop {
    @media (max-width: $small_desktop){
        @content;
    }
}


@mixin tablet_vertical {
    @media (max-height: $tablet_vertical){
        @content;
    }
}