.frontend-home {

    .page-container {
        max-width: 750px;
        margin: 0 auto;
        position: relative;
    }

    .haircut-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        background: #fff;
        padding: 25px 10px 60px 10px;
        border-radius: 10px 10px 0 0;
        margin: 0 10px;

        @include tablet {
            max-width: 100%;
        }

        @include mobile {
            padding: 15px 8px 30px 8px;
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }

        > a {

            padding: 10px;
            border: 2px solid #feefd8;
            margin: 0 -2px -2px 0;
            transition: all linear .2s;
            cursor: pointer;

            @include mobile {
                padding: 6px;
            }

            &:hover {
                background: #feefd8;

                img {
                    border-color: #ffffff;
                }
            }

            .haircut-item {
                
    
                &--image {
                    img {
                        width: 100%;
                        border: 2px solid #f8d8a8;
                        border-radius: 8px;
                        transition: all linear .2s;
                        height: auto;
                    }
                }
    
                &__content {
                    display: grid;
                    // grid-template-columns: 3fr 1fr;
                    align-items: center;
                    padding: 8px 0;
                    grid-gap: 10px;
                    span {
                        color: #d3a03e;
                        font-family: $primary-font;
                    }
    
                    &--name {
                        font-size: 20px;
                        line-height: 20px;
                        color: #8a5c08 !important;
                    }
    
                    &--price {
                        text-align: right;
                        font-size: 30px;
                        font-weight: 600;
                        line-height: 30px;
    
                        @include mobile {
                            font-size: 22px;
                        }
                    }
                }
            }
            
        }

    }
}

