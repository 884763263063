.admin-user-info {

  @include mobile {
   table {
      tr {
         th:last-child,
         td:last-child {
            display: none;
         }
      }
   }
  }
  .userEmail_add {
      display: flex;
      flex-direction: column;
      span {
         display: inline-block;
         background-color: rgba($clr-2, .4);
         padding: 2px 0;
         border-radius: 50px;
         font-size: 12px;
         max-width: 90px;
         text-align: center;
      }
  }

   .admin-table-header {
      padding: 0 15px;
      background-color: $clr-4;
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
   }

   .action-header {
      display: flex;
      align-items: center;

      @include mobile {
         margin: 5px 0;
      }

      p {
         margin-left: 10px;
      }
   }
}