.yearly-dayoff-container{
   max-width: 600px;

   .btn-submit {
      margin-right: 10px;
   }

   .rmdp-day-picker  {
      div, span {
         font-size: 14px !important;
      }
   }

   .rmdp-header-values span{
      font-size: 18px !important;
   }

   .rmdp-container  {
      width: 100%;
      input {
         padding: 20px 20px;
         width: 100%;
         border-radius: 0;

        
      }

      .rmdp-day-picker > div {
         min-width: 270px;
      }

    
   }

   .display-dayoff {
      margin-bottom: 16px;
      ul {
         max-height: 350px;
         overflow-y: scroll;
         border: 1px solid $clr-2;
         user-select: none;

         li {
         display: inline-block;
         background-color: $clr-4;
         width: 90px;
         text-align: center;
         padding: 5px;
         border: 2px solid #eee;
         cursor: pointer;
         position: relative;

         &:hover {
            &::after {
               opacity: 1;
            }
         }

         &::after{
            content: 'Delete';
            background-color: $clr-6;
            color: $clr-4;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 35px;
            opacity: 0;
         }
      }
      }
   }
 
}

