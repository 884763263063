#single-normal {
    display: flex;
    justify-content: center;
    padding: 10px 0;

    button {
        font-family: $primary-font;
        text-align: center;
        font-size: 20px;
        color: #fff;
        background: $clr-2;
        height: 61px;
        border-color: $clr-2;

        &:hover {
            background: $clr-3;
        }
    }

    .a2cldr-list {
        li {
            padding: 0 ;

            a {
                display: block;
                padding: 14px 0;
                font-family: $primary-font;
                font-size: 18px;
            }
        }
    }

    .a2cldr.active .a2cldr-list {
        transform: translateY(-129%);
    }
}