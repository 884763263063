.checkout-timeslot {
    background-color: #fff;
    margin-top: 5px;
    padding: 30px 20px;

    &__time {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        grid-gap: 8px;
        margin-top: 10px;

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background-color: #101010;
            border:1px solid #101010;
            &:hover {
                color: #fff;
                background-color: #101010;
                border:1px solid #101010;
            }
        }

        label {
            text-align: center;
            font-size: 18px;
            height: 45px;
            line-height: 45px;
            font-weight: 500;
            background-color: #f2fff9;
            border: 1px solid #2ed986;
            border-radius: 5px !important;
            overflow: hidden;
            display: inline-block;

            &:hover {
                color: #101010;
                border:1px solid #08c369;
            }
        }
    }

}