.d-tablet-none{
    @include tablet{
        display: none;
    }
}

.d-mobile-none{
    @include mobile{
        display: none;
    }
}