$clr-1: #E9975A;
$clr-2: #C3965D;
$clr-3: #351B1B;
$clr-4: #ffffff;
$clr-5: #7d4646;
$clr-6: #e20b0b;
$clr-7: #1890ff;


$primary-font: 'Barlow', sans-serif;

// responsive breakpoints
$small_mobile: 375px;
$mobile: 575.98px;
$tablet: 768px;
$large_tablet: 991px;
$laptop: 1199.98px;
$small_desktop: 1600px;
$tablet_vertical: 667px;