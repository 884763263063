.dayoff-card{
    display: grid;
    max-width: 800px;
    grid-gap: 10px;

    button {
        width: 80%;
    }

    .ant-card-body {
        padding: 12px;
    }

    .dayoff-card-date {
        border: 1px solid #eee;
        padding: 5px 8px;
        margin: 1px;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
    }
    

}

.inner-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 5px;

    .min-height {
        padding: 0 15px;
    }

    li {
        padding: 10px 0;

        &:not(:last-child) {
            border-bottom: 1px solid #eee;
        }

        label {
            font-size: 18px;
            font-weight: 500;
            display: inline-block;
        }

        p {
            padding-bottom: 10px;
        }

    }

    .ant-card-head {
        padding: 0;
    }

    .ant-card-head-title {
        padding: 5px 15px;
        background: #ADD8FD;
    }

}


.admin-break-time-drawer {
    .ant-drawer-content-wrapper {
        max-width: 380px !important;
        overflow: hidden;
    }
}

.break-time-layout {

    padding-bottom: 40px;

    &__item {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .label {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;
        display: inline-block;
    }

    .ant-radio-group {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
    }

    .rmdp-wrapper {
        border: 1px solid #ddd;

        .rmdp-day, .rmdp-week-day {
            width: 40px;
            height: 40px;
            font-size: 14px;

            .sd {
                font-size: 16px;
            }
        }
    }

    .timeslot-checkbox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2px;
        label {
            margin: 0;
            background-color: #eee;
            display: inline-block;
            padding: 8px 2px;
            text-align: center;
            user-select: none;

            span {
                padding: 0;
            }
        }
    }

}