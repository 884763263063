.admin-panel {
   .frontend-container {
      padding: 0;
      max-width: 700px;
      top: 0;
      height: auto;
      min-height: auto;
      background-color: transparent;
      margin: 0 0 0 0;
   }
   
   .information-container,
   .reschedule-container {
      padding: 0;
      margin-top: 0;
      background-color: transparent;
   }
}
