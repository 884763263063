/*
    -------------------------
    float-bob
    -------------------------
*/ 

%float-bob{
    animation-name: float-bob; 
    animation-duration: 7s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes float-bob {
    0% {
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);
    }
  
    50% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
    }
  
    100% {
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);
    }
}

/*
    -------------------------
    zoom-fade
    -------------------------
*/

%zoom-fade{
    animation-name: zoom-fade; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes zoom-fade {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
}

/*
    -------------------------
    float_up_down
    -------------------------
*/

%float_up_down{
    animation-name: float_up_down; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes float_up_down {
    0% {
      transform: translateY(0px);
      transform: translateY(0px);
      opacity: 1;
    }
  
    50% {
      transform: translateY(30px);
      transform: translateY(30px);
      opacity: 1;
    }
  
    100% {
      transform: translateY(0px);
      transform: translateY(0px);
      opacity: 1;
    }
}

/*
    -------------------------
    rotateme
    -------------------------
*/

%rotateme{
  animation-name: rotateme; 
  animation-duration: 24s; 
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateme {
    
  0% {
     transform: rotate(0deg);
     opacity: 1;
   }

 50% {
     transform: rotate(180deg);
   }
 100% {
     transform: rotate(360deg);
     opacity: 1;
   }
}

/*
    -------------------------
    ripple-white3
    -------------------------
*/

%ripple-white3{
  -webkit-animation: ripple-white3 2.5s linear infinite;
  animation: ripple-white3 2.5s linear infinite; 
}

@-webkit-keyframes ripple-white3 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1); }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0); } }

@keyframes ripple-white3 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1); }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0); } }