*{
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: 'Barlow';
}

.ant-picker-content td {
    font-size: 17px;
}

.disable-calendar {
    pointer-events: none;
    filter: grayscale(1);
}

.w-100 {
    width: 100%;
}

.align-center {
    text-align: center;
}

.ant-modal-content {
    background-color: #F1F1F1 !important;
}

body {
    overflow-x: clip;
}

button:focus,
button{
    border: 0;
    outline: 0;
    cursor: pointer;
}

a{
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}

ul, ol{
    list-style-type: none;
}



.disable-dayoff {
    font-size: 30px;
    width: 100%;
    text-align: center;
    color: $clr-6;
    text-align: center;
    display: block;
    line-height: 20px;

    @include tablet {
        font-size: 20px;
        display: block;
    }
    span {
        display: block;
        font-size: 16px;

        @include tablet {
            display: none;
        }
    }
}


.checkout-page-root {
    
    .ant-picker-calendar-date-today {
        &::before {
            display: none;
        }        
     }
}


.ant-picker-cell::before{
    height: 40px;

    @include tablet {
        height: 30px;
    }
}

.calander-container {
    margin-top: 50px;

    > div {
        padding: 20px;
        border-radius: 5px;
    }
}


.ant-drawer-content-wrapper {
    width: 450px !important;

    @include mobile {
        width: 100% !important;

        .text-opt-in, .policy-opt-in {
            padding-bottom: 80px;
        }
    }
}


.policy-opt-in.reschedule {
    margin-bottom: 20px;
}

.text-base {
    font-size: 15px;
    color: #050505;
}


.ant-modal {
    max-width: calc(88vw - 16px);
    margin: 8px auto;
}

.ant-menu-inline-collapsed {
    width: 50px;
}


.individual-dayoff, 
.yearly-dayoff {
    .ant-picker-panels {
       flex-wrap: wrap !important;
    }
    @include mobile {
       .ant-picker-panel-container {
          width: 290px;
       }
    }
 }
 


body {
    background-image: linear-gradient(to bottom, #2c2c2c, #050505) ;
    min-height: 100vh;
    background-attachment: fixed;
}

.transparent-bg {
    background-color: transparent !important;
    padding: 0 0 80px 0 !important;
}

