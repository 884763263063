.page-container.thankyou-container {
    background-color: #F0F0F0;
    margin-top: 50px;
    padding: 30px 0 100px 0;

    @include tablet {
        margin-top: 0;
        border-radius: 0;
        min-height: 100vh;
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        img {
            max-width: 320px;
        }
    }

    @include mobile {
        img {
            max-width: 250px;
        }
    }

    .google-calendar {
        background-color: #00A95A;
        color: #fff;
        padding: 20px 40px;
        display: inline-block;
        font-size: 24px;
        font-family: $primary-font;
        border-radius: 100px;

        @include mobile {
            padding: 15px 20px;
            margin-top: 10px;
            font-size: 20px;
        }

        &:hover {
            background-color: #00813E;
        }
    }

    .thankyou-container {
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
        img {
            width: 100%;
        }

        h2 {
            font-family: $primary-font;
            font-size: 34px;

            @include mobile {
                font-size: 24px;
            }
        }
    }

    .finish-appointment {
        background-color: #F7BC42;
        color: #141414;
        padding: 14px 100px;
        display: inline-block;
        font-size: 24px;
        border-radius: 100px;
        font-weight: 600;
        margin-bottom: 20px;
        font-family: $primary-font;

        @include mobile {
            padding: 12px 100px;
            margin: 5px auto;
            font-size: 20px;
        }

        &:hover {
            background-color: #bb861c;
        }
    }
}