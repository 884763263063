.reschedule-container {


   .login-form {
      max-width: 500px;
      margin:0 auto;
      padding: 0 15px;

      @include mobile {
         padding: 0 15px 60px 15px;
      }
      
      > form {
         background-color: $clr-4;
         padding: 50px 30px;
         border-radius: 8px;
         @include mobile {
            padding: 25px 15px;
         }
      
      }

    

      .ant-input-affix-wrapper {
         height: 45px;
         border-radius: 6px;
         &-focused {
            box-shadow: none;
            border-color: $clr-2 !important;
         }
      }

      button {
         width: 100%;
         font-size: 16px;
         font-family: $primary-font;
         padding: 10px 0;
         height: auto;
         background-color: $clr-2;
         border-color: $clr-2;
         border-radius: 6px;
         &:hover {
            background-color: lighten($clr-2, 10%);
         }
      }
   }
   .reschedule-form {
      padding-bottom: 60px;

      @include tablet {
         padding: 0 15px 50px 15px;
      }
   }

   .information-container {
      background-color: $clr-4;
      padding: 10px 25px 40px 25px;
      max-width: 750px;
      border-radius: 5px;
      margin: 0 auto;

      @include mobile {
         padding: 10px 10px 40px 10px;
         
         .ant-alert-info {
            flex-direction: column;
            padding: 15px 9px;

            svg {
               display: none;
            }
         }
      }

      .ant-alert-info {
         background-color: lighten($clr-2, 30%);
         border-color: $clr-2;
         svg {
            color: $clr-2;
         }
      }



      label {
         display: block;
         margin-top: 30px;
         margin-bottom: 5px;
         font-family: $primary-font;
         font-size: 16px;
      }

      .ant-select, .ant-picker {
         width: 100%;
         cursor: pointer;
         height: 40px;
         .ant-select-selector,
         .ant-select-selection-item {
            height: 40px;
            line-height: 40px;
         }
      }

      .group-buttons {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-gap: 20px;

         &.inputs {
            margin-bottom: 40px;
         }

         
         @include mobile {
            grid-gap: 10px;
         }
         
         button {
            padding: 10px;
            height: auto;
            font-size: 14px;
            font-family: $primary-font;
            border-radius: 5px;
         }
      }

   }

   



}

