.frontend-page-title {
    max-width: 750px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 15px;
    margin-top: 30px;

    @include tablet {
        padding: 30px 10px;
    }

    @include mobile {
        padding: 20px 10px;
    }

    // @include tablet {
    //     flex-direction: column;
    // }

    h2, span, a {
        font-family: $primary-font;
    }

    h2, a {
        color: #f7c86f;
    }

    span {
        color: #e8e8e8;
        font-size: 24px;
    }

    h2 {
        text-transform: uppercase;
        margin: 0;
        font-size: 46px;
        font-weight: 600;
        word-spacing: 8px;

        @include tablet {
            font-size: 36px;
        }

        @include mobile {
            font-size: 24px;
        }
    }

    a {
        border: 2px solid #f7c86f;
        padding: 8px 25px;
        font-size: 20px;
        border-radius: 3px;
        transition: all linear .2s;

        // @include tablet {
        //     margin-top: 15px;
        // }

        @include tablet {
            font-size: 18px;
            padding: 6px 15px;
        }

        &:hover {
            background: #f7c86f;
            color: #2c2c2c;
        }
    }
}