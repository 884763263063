.frontend-header {
    height: 270px;
    line-height: 160px;
    text-align: center;
    background-image: url('./../../images/header-background.png');
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;

    @include tablet {
        display: none;
    }

    @include mobile {
        height: 200px;
    }

    img {
        width: 350px;

        @include mobile {
            width: 300px;
        }
    }
}