.primary-btn {
  a, button {
    background-color: $clr-2;
    color: $clr-4;
    @extend %family-1;
    @extend %transition;
    padding: 12px 30px;
    border-radius: 100px;
    border: 2px solid $clr-5;
    display: inline-block;
    font-size: 20px;

    @include tablet {
      padding: 10px 30px;
      font-size: 16px;
      border-width: 1px;
    }

    &:hover {
      background-color: $clr-3;
    }

  }
}