.admin-login-page {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   font-family: $primary-font;

   .admin-login-form {
      background-color: #fff;
      padding: 40px 30px;
      border-radius: 10px;

      @include mobile {
         padding: 40px 10px;
         margin: 0 10px;
         border-radius: 5px;
      }

      max-width: 400px;
      width: 100%;

      .ant-input-affix-wrapper {
         line-height: 40px;
      }

      .ant-form-item:last-child {
         margin-bottom: 0;  
      }

      button {
         width: 100%;
         height: 50px;
         font-family: $primary-font;
         font-size: 20px;
      }
   }


}