.ashraf-camera {

    max-width: 600px;

    .camera-button {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        padding: 20px 0;

        @include mobile {
            grid-gap: 10px;
        }

        button {
            background-color: $clr-2;
            padding: 15px;
            color: $clr-4;
            font-size: 18px;
            font-family: $primary-font;

            @include mobile {
                padding: 10px;
            }

            &:hover {
                background-color: #101010;
            }
        }

    }

    video {
        border: 2px solid #a1a1a1;
    }
} 