.admin-panel {
    background-color: #efefef;

    .dashboard-container {
        width: 100%;
        padding: 100px 50px;

        @include tablet {
            padding: 64px 15px;
        }

        @include mobile {
            padding: 64px 5px;
        }
    }

    .filter-container {
        .ant-select-selector {
            height: 40px !important;
    
            .ant-select-selection-placeholder {
                line-height: 40px;
            }
        }
    
        .ant-select-selection-item {
            line-height: 40px !important;
        }
    }

    

    .reschedule-container .information-container label {
        margin-top: 0;
    }

}