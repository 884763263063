.admin-user-container {
   .user-showcase {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
      grid-gap: 5px;
      .user-data-item {
         background-color: $clr-4;
         padding: 15px 20px;
         .user-name {
            border-bottom: 2px solid $clr-2;
            padding-bottom: 15px;
            color: $clr-2;
         }

         .user-info {
            border-bottom: 1px solid #eee;
            padding: 7px 0;
         }

         button {
            width: 100%;
            margin-top: 15px;
         }
         
      }

   }

   .user-footer {
      margin-top: 40px;
   }

}