.vip-label {
   margin-left: 10px;
   background: #e5ffe5;
   padding: 4px 17px;
   color: green;
   border: 1px solid #50be50;
   font-weight: 500;
   border-radius: 8px;
   font-size: 15px;
}

.admin-appointment-container {
   max-width: 600px;

   .dashboard-appointments {
      .ant-collapse-item-disabled {
         .ant-collapse-header {
            background-color: #fff0f0;
            color: red;
         }  
      }
   }

   .appointment-filter-option {
      padding-bottom: 25px;

      h4 {
         padding-bottom: 10px;
         font-size: 18px;
         color: $clr-2;
      }

      .filter-container {
         display: grid;
         grid-template-columns: 1fr;
         grid-gap: 10px;

         .ant-picker-calendar-date-value {
            display: none;
         }


         td:not(.ant-picker-cell-selected){
            .ant-picker-calendar-date-content {
               
               font-size: 16px;

               .active-date {
                  background-color:$clr-2;
                  color: #fff;
               }  
            }
   
         }
         .ant-picker-calendar-mode-switch {
            display: none;
         }

         .ant-picker-calendar-header {
            display: grid !important;
            grid-template-columns: 1fr 1fr;

            .ant-select {
               width: 100%;
            }
         }
      }
   }

   .filter-result {
      background-color:$clr-2;
      color: $clr-4;
      padding: 15px 20px;
   }

   .appointment-content-panel {

      .ant-collapse-item {
         .ant-collapse-header {
            font-size: 18px;
         }

         .anticon-right {
            line-height: 25px;
         }
      }

      table {
         table-layout: fixed;
         border-collapse: collapse;
         width: 100%;
         
         tr {

            td {
               padding: 5px 0;
               border-bottom: 1px solid #eee;
               font-size: 16px;

               strong {
                  min-width: 80px;
                  display: inline-block;

                  @include small-mobile {
                     display: block;
                  }

               }

               button {
                  margin-top: 10px;
                  margin-right: 5px;
               }
            }

         }

      }  
   }

}


.ashraf-see-photos {
   width: 100% !important;
   max-width: 570px !important;
   padding: 8px !important;

   .ant-modal-body {
      padding: 48px 10px 10px 10px !important;
   }

   img {
      width: 100%;
   }
}


