.admin-panel {
   font-family: $primary-font;
   display: flex;

   h4, p {
      margin: 0;
      padding: 0;
   }
   .admin-header {
      &__top {
         display: flex;
         align-items: center;
         background-color: lighten($clr-7, 30%);
         position: fixed;
         top: 0;
         left: 0;
         width: 100%;
         z-index: 99;

         &--btn {
            height: 50px;
            width: 50px;
            padding: 0;
            margin-right: 20px;
         }

         &--title {
            font-size: 20px;
         }

         .avator-icon {
            position: absolute;
            right: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
               font-size: 18px;
               color: $clr-6;
            }

            .logout {
               @include mobile {
                  display: none;
               }
            }

            &:hover .ant-avatar {
               background-color: $clr-6;
               svg {
                  color: $clr-4;
               }
            }

            .ant-avatar {
               background-color: $clr-4;
               margin-left: 10px;
               transition: .4s;
            }
         }
      }
   }

   .ant-menu-root {
      margin-top: 50px;
      min-height: calc(100vh - 80px);
      position: sticky;
      top: 50px;
      min-width: 52px;
   }

   .admin-content {
      margin-top: 50px;
      background: #f3f3f3;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
   }
   
}