.barber-page {
    .page-container {
        background-color: transparent !important;
        padding: 0 0 15px 0 !important;
    }   
    
    .loading {
        img {
            filter: invert(1) !important;
        }
    }
}

.barbers-container {
    
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    margin: 0 10px;

    .barber-item {
        background-color: #f0f0f0;
        padding: 25px;
        border-radius: 4px;
        transition: linear .2s;
        border: 3px solid #f0f0f0;

        @include mobile {
            padding: 5px 10px;
        }

        &:hover {
            background-color: #fef6e8;
            border-color: #f7c86f;

            img {
                border-color: #f7c86f;
            }
        }

        .name {
            font-size: 22px;
            display: inline-block;
            cursor: pointer;
            text-align: left;
            font-weight: 600;
            line-height: 1;
        }

        &__header {
            text-align: left;
            display: flex;
            align-items: center;
            gap: 25px;

            > div {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .price {
                display: inline-block;
                font-size: 38px;
                font-weight: 600;
                color: #df9305;
                margin-left: auto;
            }

            .vip-prices {
                text-align: right;
                margin-left:  auto;

                .regular {
                    text-decoration: line-through;
                    font-size: 18px;
                    color: #DB4437;;
                    line-height: 1;
                    margin-bottom: -6px;
                }

                .vip {
                    font-size: 40px;
                    line-height: 1;
                    font-weight: 700;
                    color: #4285F4;
                }
            }

            .service {
                font-size: 17px;
                display: block;
                text-align: left;
                max-width: 200px;
                line-height: 1.3;
                margin-top: 7px;
            }

            img {
                --size: 60px;
                width: var(--size);
                min-width: var(--size);
                height: var(--size);
                min-height: var(--size);
                object-fit: cover;
                object-position: center center;
                border-radius: var(--size);
                border: 2px solid #bcbcbc;
                transition: linear .2s;
                cursor: pointer;
            }
        }

        &__meta {
            display: flex;
            align-items: center;
            gap: 20px;
            margin: 9px 0;

            @include small_mobile {
                gap: 12px;
            }
        }

        &__content {
            &--dayoff {
                display: block;

                .weekends {

                    max-width: 100%;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    @include small_mobile {
                        gap: 2px;
                    }

                    span {
                        --size: 25px;
                        display: inline-block;
                        height: var(--size);
                        width: var(--size);
                        min-height: var(--size);
                        min-width: var(--size);
                        background-color: #0F9D58;;
                        color: #2c2c2c;
                        line-height: var(--size);
                        font-size: 10px;
                        font-family: "Barlow", sans-serif;
                        border-radius: 50%;
                        text-align: center;
                        font-weight: 500;
                        color: #fff;
                        &:not(:first-child) {
                            border-left: 1px solid #fff;
                        }
                    }

                    .disable {
                        background-color: #DB4437;;
                    }
                }
            }

            &--notice {
                color: #2c2c2c;
                font-size: 15px;
                font-family: $primary-font;
                background-color: #FFF;
                padding: 4px 10px;
                margin: 8px 0 0 0;
                border-radius: 1px;
            }

            ul {
                display: flex;
                justify-content: space-between;

                li {
                    color: #2c2c2c;
                    font-size: 15px;
                    font-family: $primary-font;
                    position: relative;
                    vertical-align: middle;
                    display: flex;
                    align-items: center;

                    &::before {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: inline-block;
                        margin-right: 8px;
                        border-radius: 50%;
                    }
                }

                .dayoff:before {
                    background-color: #ff7a7a;
                }

                .available:before {
                    background-color: #f7c86f;
                }
            }
        }

        &__footer {
            display: flex;
            margin-top: 10px;
            gap: 10px;
            justify-content: space-between;

            .vip-code {
                display: flex;
                align-items: center;
                gap: 5px;

                label {
                    font-size: 16px;
                    font-weight: 600;
                    color: #cd8808;
                }

                input {
                    max-width: 130px;
                    border: 0;

                    &:focus {
                        opacity: 1;
                    }
                }
            }

            button {
                font-size: 16px;
            }

            .continue-btn {
                border: 1px solid #442d03;
                color: #fff;
                background-color: #6f4800;
                min-width: 120px;
            }
        }
    }
}

.profile-info {
    .ant-drawer-content-wrapper {
        width: 100% !important;
        height: 450px !important;
    }

    .ant-drawer-body {
        text-align: center;
    }

    h2 {
        font-size: 24px;
        font-weight: 600;
        span {
            opacity: .6;
            font-weight: 400;
            font-size: 20px;
        }
    }

    label {
        font-size: 18px;
        display: block;
        margin-bottom: 20px;
        margin-top: 20px;
        text-decoration: underline;
        line-height: 1;
        font-weight: 500;
        text-align: center;
        color: #cd8808;
    }

    .profile-image {
        width: 120px;
        height: 120px;
        border: 2px solid #cd8808;
        border-radius: 120px;
        object-fit: cover;
        margin-bottom: 8px;
    }

    .barber-spalcities {

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        max-width: 320px;
        margin: 0 auto;

        &--title {
            font-size: 32px;
            margin-top: 10px;
            color: $clr-7;
        }

        &--item{
            color: #2c2c2c;
            margin-bottom: 0;
            background-color: #fff;
            font-size: 14px;
            padding: 3px;
            border-radius: 5px;
            text-transform: capitalize;
            text-align: left;
            margin-bottom: 0;

            .anticon {
                width: 25px;
                height: 25px;
                line-height: 28px;
                text-align: center;
                color: #fff;
                font-size: 15px;
                border-radius: 50%;
                margin-right: 5px;
            }



            &.bad .anticon {
                background-color: red;
            }

            &.good .anticon {
                background-color: green;
            }
        }
    }
}